







































































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Technology} from "@/components/projects/domain/technology";
import HintButton from "@/components/shared/buttons/HintButton.vue";
import {Sources} from "@/components/projects/domain/sources";
import { Icon } from '@iconify/vue2';

@Component({
  name: "project-card",
  components: {HintButton, Icon},
})
export default class TechnologyIcons extends Vue {
  @Prop({type: Array, default: ()=>[]})
  stack!: Technology[];

  @Prop({type: Array, default: ()=>[], required: false})
  seeOn!: { source: Sources, url: string, description?: string }[];

  isShowed(s: Technology): boolean {
    return this.stack.some((f)=>f==s)
  }

  getSource(s: Sources) {
    return this.seeOn.find((f)=>f.source==s)
  }

  showSource(s: Sources): boolean {
    return this.seeOn.some((f)=>f.source==s)
  }
}
