
































import { Component, Vue } from 'vue-property-decorator';
import About from "@/components/About.vue";
import SelectionView from "@/components/SelectionView.vue";
import Projects from "@/components/projects/views/Projects.vue";
import Resume from "@/components/projects/views/Resume.vue";

@Component({
  components: {
    Resume,
    Projects,
    SelectionView,
    About,
  },
})
export default class App extends Vue {
  selectedView: number = 0

  get defaultStyle(){
    return 'font-weight-bold mx-1 elevation-0'
  }

  get selectedStyle() {
    return 'font-weight-bold mx-1 elevation-0 cyan--text'
  }
  changeView(view: number) {
    this.selectedView = view
  }

  get blogSubdomain() {
    return `https://blog.${window.location.host}`
  }
}
