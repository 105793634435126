




























































































































import {Component, Vue} from 'vue-property-decorator';
import HintButton from "@/components/shared/buttons/HintButton.vue";
import ProjectCard from "@/components/projects/components/ProjectCard.vue";
import {Project} from "@/components/projects/domain/project";
import {projects} from "@/assets/projects.json"
import {Technology} from "@/components/projects/domain/technology";

@Component({
  name: "projects",
  components: {ProjectCard, HintButton}
})
export default class Projects extends Vue {

  ELEMENTS_PER_PAGE = 6

  byLanguage: boolean = false

  page: number = 1

  projects: Project[] = projects;

  filters: Technology[] = []

  get filteredProjects(): Project[] {
    if (this.filters.length == 0) {
      return this.projects
    }
    return this.projects.filter(p=>p.stack.some(t=>this.filters.some(tt=>tt==t)))
  }

  isFilterApplied(filter: Technology) {
    return this.filters.some(f=>f == filter)
  }

  setFilter(filter: Technology) {
    this.page = 1;
    if (this.isFilterApplied(filter)) {
      this.filters = this.filters.filter(f=>f!=filter);
    } else {
      this.filters.push(filter)
    }
  }

  get numberOfPages(): number {
    return Math.floor((this.filteredProjects).length / this.ELEMENTS_PER_PAGE) + (((this.filteredProjects).length % this.ELEMENTS_PER_PAGE) == 0 ? 0 : 1);
  }

  get listedProjects(): Project[] {
    return this.filteredProjects.slice((this.page - 1) * this.ELEMENTS_PER_PAGE, (this.page - 1) * this.ELEMENTS_PER_PAGE + this.ELEMENTS_PER_PAGE)
  }
}
