































import {Component, Vue} from 'vue-property-decorator';
import {jobs} from "@/assets/jobs.json"
import {Job} from "@/components/projects/domain/job";

@Component({
  name: "resume",
  components: {}
})
export default class Resume extends Vue {
  jobs: Job[] = jobs
}
