
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: "about"
})
export default class About extends Vue {

}
