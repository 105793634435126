import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        djangoGreen: '#092d1f',
        nodejs: '#68a063',
        vueGreen: '#40b27f',
        socketIO: '#424242',
        flask: '#212121',
        react: '#5ed4f3',
        cpp: '#00203e',
        unity: "#000",
        godot: '#3f8cb9',
        web3: '#282826',
        tensorflow: '#f26617',
        qt: '#41cd52',
        assembly: '#002aff',
        java: '#f0931f',
        js: '#efd81d',
        ts: '#0076c6',
        python: '#4987b8'
      },
    },
  },
});
