




























import Vue from "vue";
import Component from "vue-class-component";
import TechnologyIcons from "@/components/projects/components/TechnologyIcons.vue";
import {Prop} from "vue-property-decorator";
import {Project} from "@/components/projects/domain/project";

@Component({
  name: "project-card",
  components: {TechnologyIcons},
})
export default class ProjectCard extends Vue {
  @Prop()
  project!: Project

}
