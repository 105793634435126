




















import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component({
  name: "selection-view"
})
export default class SelectionView extends Vue {
  @PropSync("selectedView", {type: Number, default: 0})
  selectedViewProp!: number
}
